import { startsWith } from "lodash"
const { withPrefix: gatsbyWithPrefix } = require("gatsby")

export default function parseVideoURL(url) {
  function getParm(url, base) {
    var re = new RegExp("(\\?|&)" + base + "\\=([^&]*)(&|$)")
    var matches = url.match(re)
    if (matches) {
      return matches[2]
    } else {
      return ""
    }
  }

  var retVal = {}
  var matches
  if (url.indexOf("youtube.com/watch") != -1) {
    retVal.channel = "youtube"
    retVal.videoId = getParm(url, "v")
  } else if ((matches = url.match(/vimeo.com\/(\d+)/))) {
    retVal.channel = "vimeo"
    retVal.videoId = matches[1]
  } else {
    retVal.channel = "custom"
    retVal.videoId = gatsbyWithPrefix(url)
  }
  return retVal
}
