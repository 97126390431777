import { startsWith } from "lodash";
const { withPrefix: gatsbyWithPrefix } = require("gatsby");

export default function withPrefix(url) {
  if (
    startsWith(url, "#") ||
    startsWith(url, "http://") ||
    startsWith(url, "https://")
  ) {
    return url;
  }
  return gatsbyWithPrefix(url);
}
